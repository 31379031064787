import React, { Fragment } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../components/header';
import Footer from '../components/footer';
import ContactImg from '../assets/images/contactus.png';
import ContactForm from '../components/contactForm';
import Image from '../components/image';

const Contact = ({ location }) => {
  const [loading, setLoading] = React.useState(false);
  const onFormSubmit = async (submissionValues, resetForm) => {
    if (
      submissionValues.name &&
      submissionValues.email &&
      submissionValues.website &&
      submissionValues.phone &&
      submissionValues.project
    ) {
      const today = new Date();
      setLoading(true);
      axios
        .post(
          'https://leads.civsav.com/cbgwholesale/contact',
          {
            data: {
              name: submissionValues.name,
              email: submissionValues.email,
              website: submissionValues.website,
              phone: submissionValues.phone,
              project: submissionValues.project,
              date: today.toLocaleDateString('en-US'),
            },
          }
        )
        .then((res) => {
          resetForm();
          setLoading(false);
          toast.success('Your request has been submitted');
        })
        .catch(() => {
          toast.error('Something went wrong!');
        });
    } else {
      toast.error('All Fields are Required!');
    }
  };

  return (
    <Fragment>
      <main>
        <Header pathname={location.pathname} />
        <section class="hero -gold ">
          <div
            class="hero-stack -rp contact-banner"
            style={{
              backgroundImage: `url(${ContactImg})`,
            }}
          >
            <h1 class="hero-headline -animatedLine -py">
              Contact Us
              <br />
            </h1>
            <Image
              src={'contactus.png'}
              className="hero-innerImage-outer"
              imgClassName="hero-innerImage"
              placeholder="blurred"
            />
          </div>
        </section>

        <ContactForm
          image="sub-banner.png"
          onFormSubmit={onFormSubmit}
          loading={loading}
        />
        <Footer isTopborder={true} />
      </main>
      <ToastContainer />
    </Fragment>
  );
};

export default Contact;
